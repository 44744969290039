import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import { RotatingLines } from  'react-loader-spinner';

const LazyHome = React.lazy(() => import ("./pages/Home"));
const LazyAboutus = React.lazy(() => import ("./pages/Aboutus"));
const LazyOurproduct = React.lazy(() => import ("./pages/OurProduct"));
const LazyContactus = React.lazy(() => import ("./pages/Contactus"));



function App() {
  return (
   <>
      {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={<React.Suspense fallback={<div className="d-flex justify-content-center align-items-center hvh-100"><div className="d-flex justify-content-center align-items-center rotateline">{<RotatingLines strokeColor="#13017c" strokeWidth="2" animationDuration="0.75" width="96" visible={true}/>}</div></div>}><LazyHome/> </React.Suspense>} />
          <Route path="/about-us/" element={<React.Suspense fallback={<div className="d-flex justify-content-center align-items-center hvh-100"><div className="d-flex justify-content-center align-items-center rotateline">{<RotatingLines strokeColor="#13017c" strokeWidth="2" animationDuration="0.75" width="96" visible={true}/>}</div></div>}><LazyAboutus/> </React.Suspense>} />
          <Route path="/our-product/" element={<React.Suspense fallback={<div className="d-flex justify-content-center align-items-center hvh-100"><div className="d-flex justify-content-center align-items-center rotateline">{<RotatingLines strokeColor="#13017c" strokeWidth="2" animationDuration="0.75" width="96" visible={true}/>}</div></div>}><LazyOurproduct/> </React.Suspense>} />
          <Route path="/contact-us/" element={<React.Suspense fallback={<div className="d-flex justify-content-center align-items-center hvh-100"><div className="d-flex justify-content-center align-items-center rotateline">{<RotatingLines strokeColor="#13017c" strokeWidth="2" animationDuration="0.75" width="96" visible={true}/>}</div></div>}><LazyContactus/> </React.Suspense>} />
        </Routes>
      {/* </BrowserRouter> */}
   </>
  );
}

export default App;
